<template>
    <div class="pr-0 pl-0 pt-0 pb-0" style="cursor: pointer; display: inline-block" @click="_sort()">
        {{text}}
        
        <span>
            <v-icon v-if="sort.split(' ')[0] == field" class="ml-1" size="20" color="grey darken-2">{{ascending}}</v-icon>
            <v-icon v-else-if="sort.split(' ')[0] == '-'+field" class="ml-1" size="20" color="grey darken-2">{{descending}}</v-icon>
            <v-icon v-else-if="sort.split(' ').includes('-'+field)" class="ml-1" size="20" disabled>{{descending}}</v-icon>
            <v-icon v-else class="ml-1" size="20" disabled>{{ascending}}</v-icon>
        </span>
    </div>
</template>

<script>
export default {
    props:{
        text:{
            type: String,
            default: null
        },

        defaultSort:{
            type: String,
            default: null
        },

        field:{
            type: String,
            default: null
        },

        type:{
            type: String,
            default: "alphanum" //numeric, boolean, date, time
        },

        sort:{
            type: String,
            default: ""
        }
    },

    model: {
        prop: 'sort',
        event: 'change'
    },

    computed:{
        ascending(){
            switch(this.type){
                case "alphanum":
                    return "mdi-sort-alphabetical-ascending";
                
                case "numeric":
                    return "mdi-sort-numeric-ascending";
                    
                case "boolean":
                    return "mdi-sort-bool-ascending-variant";

                case "date":
                    return "mdi-sort-calendar-ascending";
                    
                case "time":
                    return "mdi-sort-clock-ascending-outline";
            }
        },

        descending(){
            switch(this.type){
                case "alphanum":
                    return "mdi-sort-alphabetical-descending";
                
                case "numeric":
                    return "mdi-sort-numeric-descending";
                    
                case "boolean":
                    return "mdi-sort-bool-descending-variant";

                case "date":
                    return "mdi-sort-calendar-descending";
                    
                case "time":
                    return "mdi-sort-clock-descending-outline";
            }
        }
    },

    methods:{
        _sort() {
            let sort = this.sort;

            if(sort.substring(0, this.field.length + 1) == this.field + " ") {
                sort = "-" + this.field + " " + sort;
            } else {
                sort = this.field + " " + sort;
            }

            sort = sort.replaceAll(" " + this.field + " ", " ").replaceAll(" -" + this.field + " ", " ");

            this.$emit("change", sort);
        }
    }
}
</script>